import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import "./Page.css";
import data from ".././api/paginas.json"; // Importe o arquivo JSON
import { FaComment, FaUsers, FaInfoCircle, FaTrophy } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPix } from "@fortawesome/free-brands-svg-icons"; // Verifique se o ícone está disponível
import { FaHeart } from 'react-icons/fa';

import {
  Image,
  Text,
  Flex,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  HStack,
  Heading,
  Button,
  Box,
  Icon,
  Avatar,
  Divider,
  Progress,
  SimpleGrid,
  AspectRatio,
} from "@chakra-ui/react";
import Favorite from ".././actions/Favorite.js";

function Page() {
  const { page } = useParams(); // Captura o parâmetro da URL
  const [pageData, setPageData] = useState(null);
  const [sobre, setSobre] = useState(true);
  const [donor, setDoadores] = useState(false);
  const [mensagens, setMensagens] = useState(false);

  useEffect(() => {
    // Filtrar o resultado baseado no parâmetro da URL
    const item = data.find((item) => item.endpoint.toString() === page);
    setPageData(item);
  }, [page]); // Reexecuta o efeito quando o parâmetro da URL muda

  if (!pageData) {
    return <div>Página não encontrada</div>;
  }

  const handleSobre = () => {
    if (!sobre) {
      setSobre(true);
      setDoadores(false);
      setMensagens(false);
    }
  };

  const handleDoadores = () => {
    if (!donor) {
      setSobre(false);
      setDoadores(true);
      setMensagens(false);
    }
  };

  const handleMensagens = () => {
    if (!mensagens) {
      setSobre(false);
      setDoadores(false);
      setMensagens(true);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const Sobre = () => (
    <Flex py={2} px={3} direction={"column"} textAlign={"start"}>
      <Heading whiteSpace="pre-wrap" fontSize={"2xl"}>
        {pageData.subtitle}
        {"\n\n"}
      </Heading>

      {pageData.videoURL !== "" && 
        <AspectRatio maxW='560px' ratio={16/9} mb={5}>
          <iframe
            src={pageData.videoURL}
            allowFullScreen
          />
        </AspectRatio>
      }

      

      {pageData.description.map((paragraph, index) => (
        <Text
          key={index}
          whiteSpace="pre-wrap"
          dangerouslySetInnerHTML={{ __html: paragraph + "\n\n" }}
        ></Text>
      ))}
    </Flex>
  );

  const Doadores = ({ image, name, amount, timeAgo, isLast }) => {
    // Função para gerar iniciais do nome
    const getInitials = (name) => {
      const names = name.split(" ");
      if (names.length === 1) return names[0][0];
      return names[0][0] + names[1][0];
    };

    return (
      <>
        <Flex py={2} px={3} align="center" w={"100%"}>
          {image ? (
            <Avatar size="md" src={`/assets/images/${image}`} bg="green.100" />
          ) : (
            <Box
              size="md"
              bg="green.100"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              w="48px"
              h="48px"
            >
              <Text color="green.600" fontSize="lg" fontWeight="bold">
                {getInitials(name)}
              </Text>
            </Box>
          )}

          <Box ml={3}>
            <Text fontWeight="bold">{name}</Text>

            <Flex align="center" color="gray.500" fontSize="sm">
              <Text color="green.800" fontWeight="bold" mr={1}>
                {formatCurrency(amount)}
              </Text>
              <Text mx={1}>•</Text>
              <Text>{timeAgo}</Text>
            </Flex>
          </Box>
        </Flex>
        {!isLast && (
          <Divider
            orientation="horizontal"
            my={2}
            w="90%"
            borderColor="gray.300"
            borderWidth="1px"
          />
        )}
        {isLast && (
          <Button colorScheme="green" variant="solid" size="md" w="50%" mt={5}>
            Ver mais
          </Button>
        )}
      </>
    );
  };

  const Mensagens = ({ image, name, message, timeAgo, isLast }) => {
    // Função para gerar iniciais do nome
    const getInitials = (name) => {
      const names = name.split(" ");
      if (names.length === 1) return names[0][0];
      return names[0][0] + names[1][0];
    };

    return (
      <>
        <Flex py={2} px={3} align="start" w={"100%"}>
          {image ? (
            <Avatar
              size="lg"
              src={`/assets/images/${image}`}
              bg="green.100"
              mr={5}
            />
          ) : (
            <Box
              size="lg"
              bg="green.100"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="full"
              w="64px"
              h="64px"
              minW="64px"
              mr={5}
            >
              <Text color="green.600" fontSize="lg" fontWeight="bold">
                {getInitials(name)}
              </Text>
            </Box>
          )}

          <Box ml={3}>
            <Text fontWeight="bold">{name}</Text>

            <Flex align="center" color="gray.500" fontSize="sm">
              <Text>{timeAgo}</Text>
            </Flex>
            <Divider
              orientation="horizontal"
              my={2}
              w="90%"
              borderColor="gray.300"
              borderWidth="1px"
            />
            <Text color="gray.800">{message}</Text>
          </Box>
        </Flex>
        {!isLast && (
          <Divider
            orientation="horizontal"
            my={2}
            w="90%"
            borderColor="gray.300"
            borderWidth="1px"
          />
        )}
        {isLast && (
          <Button colorScheme="green" variant="solid" size="md" w="50%" mt={5}>
            Ver mais
          </Button>
        )}
      </>
    );
  };

  const ProgressBar = ({ value, colorScheme, size, label }) => {
    return (
      <Box>
        {label && <Text mb={2}>{label}</Text>}
        <Progress
          value={value}
          colorScheme={colorScheme}
          size={size}
          hasStripe
          isAnimated
        />
      </Box>
    );
  };

  const CardDonation = () => {
    const percentNow = (
      (pageData.AmountNow / pageData.AmountTarget) *
      100
    ).toFixed(2);

    return (
      <Card
        zIndex={999}
        position={"fixed"}
        bottom={"2%"}
        borderTop="8px"
        borderColor="green.500"
        width={{ base: "90vw", md: "60vw", lg: "50vw" }}
      >
        <CardHeader>
          <HStack justify="space-between" width="100%" mb={2}>
            <Heading size="sm">Arrecadado</Heading>
            <Heading size="sm">
              {percentNow.toString().replace(".", ",")} %
            </Heading>
          </HStack>
          <ProgressBar value={percentNow} colorScheme="green" size="sm" />
        </CardHeader>
        <CardBody>
          <HStack justify="space-between" width="100%" mt={-15}>
            <Heading color="green.500" size="sm">
              {formatCurrency(pageData.AmountNow)}
            </Heading>
            <Heading size="sm">
              de {formatCurrency(pageData.AmountTarget)}
            </Heading>
          </HStack>
        </CardBody>
        <CardFooter
          mt={-5}
          display="flex"
          flexDirection={"column"}
          textAlign={"center"}
          justifyContent="center" // Centraliza o conteúdo horizontalmente
        >
          <Text mb={2} color={"red"}>
            Encerra em {pageData.DaysLeft} dias
          </Text>
          <Button
            colorScheme="green"
            variant="solid"
            size="md"
            as="a" // Usa o <a> tag para o link
            href="#checkout" // Substitua pelo seu link real
            w="100%"
          >
            Doe Agora
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const Checkout = () => {
    // Definindo a função handleCopy dentro do componente Checkout
    const handleCopy = useCallback(() => {
      if (pageData.pixKey) {
        navigator.clipboard
          .writeText(pageData.pixKey)
          .then(() => {
            alert("Chave PIX copiada para a área de transferência!");
          })
          .catch((err) => {
            console.error("Erro ao copiar a chave PIX: ", err);
          });
      }
    }, [pageData.pixKey]);

    return (
      <>
        {" "}
        <Divider
          orientation="horizontal"
          my={5}
          w="90%"
          borderColor="gray.300"
          borderWidth="1px"
        />
        <Flex direction="column" align="center" p={4} w="100%" id="checkout">
          <Image
            src={`/assets/images/${pageData.images[0].name}`}
            alt={`Image`}
            objectFit="cover"
            width="100px" // Defina uma largura específica
            height="100px" // Defina uma altura específica igual à largura
            borderRadius={"50%"}
            mb={5}
          />
          <Heading mb={5}>Ajude agora!</Heading>
          <SimpleGrid
            columns={{ base: 2, md: 2 }} // 1 coluna em telas pequenas, 2 colunas em telas médias e maiores
            spacing={4}
            w="100%" // Garante que o grid ocupe toda a largura do contêiner
            maxW="md" // Define uma largura máxima se necessário
          >
            {pageData.checkout.amounts.map((amount, index) => (
              <Button
                key={index}
                as="a"
                href={pageData.checkout.links[index]} // Usa o link correspondente ao valor
                colorScheme="green"
                variant="solid"
                bg={index === 3 ? "red.500" : "green.500"}
                _hover={{ bg: index === 3 ? "green.600" : "green.600" }} // Adiciona cor para o estado hover
                _active={{ bg: index === 3 ? "green.700" : "green.700" }} // Adiciona cor para o estado active
              >
                <Flex
                  display={"table-column"}
                  alignItems={"center"}
                  textAlign={"center"}
                >
                  {formatCurrency(amount)}{" "}
                  {/* Texto do botão baseado no valor */}
                  {index === 3 && (
                    <Text fontSize="10px" color="white">
                      Mais doado
                    </Text>
                  )}
                </Flex>
              </Button>
            ))}
          </SimpleGrid>

          <Flex
            flexDirection={"column"}
            textAlign={"center"}
            alignContent={"center"}
            alignItems={"center"}
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)" // Sombra apenas para baixo
            borderRadius={8}
            p={5}
            mt={10}
          >
            <Icon boxSize={10} as={FaTrophy} color="yellow.400" />
            <Text
              mt={5}
              fontWeight={"bold"}
              color={"green"}
              textAlign={"center"}
            >
              {pageData.giveaway}
            </Text>
          </Flex>

          {pageData.pixKey !== "" ? (
            <>
              <Flex
                align="center"
                w="100%"
                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)" // Sombra apenas para baixo
                borderRadius={8}
                p={5}
                mt={5}
              >
                <Box
                  minW="50px"
                  minH="50px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bg="gray.200"
                  borderRadius={10}
                  mr={4} // Adiciona uma margem à direita para espaçar o ícone do texto
                >
                  <Icon
                    as={FontAwesomeIcon}
                    icon={faPix}
                    boxSize={8}
                    color="#00b7a9"
                  />
                </Box>
                <Flex flexDirection={"column"}>
                  <Text
                    fontSize="sm"
                    textAlign="start"
                    wordBreak="break-word"
                    overflowWrap="break-word"
                  >
                    Você também pode fazer o pagamento copiando a chave PIX
                    abaixo:
                  </Text>
                  <Text
                    fontSize="lg"
                    fontWeight="bold"
                    textAlign="start"
                    wordBreak="break-word"
                    overflowWrap="break-word"
                  >
                    {pageData.pixKey}
                  </Text>
                </Flex>
              </Flex>
              <Button mt={5} onClick={handleCopy}>
                Copiar Chave PIX
              </Button>
            </>
          ) : null}
        </Flex>
      </>
    );
  };

  const Share = () => {
    const handleShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: 'Compartilhe este link!',
            text: 'Confira este link incrível:',
            url: window.location.href,  // Compartilha a URL atual
          });
          console.log('Compartilhamento bem-sucedido');
        } catch (error) {
          console.error('Erro ao compartilhar:', error);
        }
      } else {
        console.log('API de Web Share não suportada neste navegador');
      }
    };
  
    return (
      <Flex
            flexDirection={"column"}
            textAlign={"center"}
            alignContent={"center"}
            alignItems={"center"}
            p={9}
            mt={5}
            width={"100%"}
          >
            <Icon boxSize={10} as={FaHeart} color="red.500" />
            <Text
              mt={3}
              fontWeight={"bold"}
              textAlign={"center"}
              fontSize={"20px"}
            >
              Ajude Compartilhando!
            </Text>
            <Text
              mb={5}
              textAlign={"center"}
              fontSize={"16px"}
            >
              Envie para os seus amigos no WhatsApp e ajude ainda mais!
            </Text>
            <Button 
              colorScheme="green" 
              size="md" 
              onClick={handleShare}
            >
              Compartilhar
            </Button>
      </Flex>
    );
  };

  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={4}
      >
        <Heading textAlign="center" size={"lg"} mb={2}>
          {pageData.title}
        </Heading>
        <Text mb={2} size={"sm"}>
          ID: {pageData.id}
        </Text>
        <Box
          pos="relative"
          w={600}
          maxH={400}
          maxW={"100%"}
          borderTop="8px solid #05a306"
          borderRadius={8}
        >
          <Swiper
            spaceBetween={8}
            slidesPerView={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            navigation
            modules={[Navigation, Pagination, A11y]}
          >
            {pageData.images.map((image, index) => (
              <SwiperSlide key={index}>
                <Image
                  src={`/assets/images/${image.name}`}
                  alt={`Image ${index + 1}`}
                  objectFit="cover"
                  w="100%"
                  h="100%"
                  borderBottomRadius={8}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Favorite />
        </Box>
        <HStack
          fontSize={14}
          justify="center" // Centraliza os itens horizontalmente
          mb={5}
          mt={5}
          position="sticky"
          top={0}
          zIndex={99}
          bg={"#f9f9fa"}
          w={"100vw"}
          boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)" // Sombra apenas para baixo
        >
          <Box
            py={2}
            px={3}
            borderRadius={!sobre ? "md" : null}
            _hover={{ cursor: "pointer" }}
            //_active={{ bg: "#05a306", color: "white" }}
            borderBottom={sobre ? "2px solid #05a306" : null} // Condicional correta para definir borderBottom
            color="gray.800"
            onClick={handleSobre}
          >
            <Icon as={FaInfoCircle} mr={3} />
            Sobre
          </Box>
          <Box
            py={2}
            px={3}
            borderRadius={!donor ? "md" : null}
            _hover={{ cursor: "pointer" }}
            //_active={{ bg: "#05a306", color: "white" }}
            borderBottom={donor ? "2px solid #05a306" : null} // Condicional correta para definir borderBottom
            color="gray.800"
            onClick={handleDoadores}
          >
            <Icon as={FaUsers} mr={3} />
            Doadores
          </Box>
          <Box
            py={2}
            px={3}
            borderRadius={!mensagens ? "md" : null}
            _hover={{ cursor: "pointer" }}
            //_active={{ bg: "#05a306", color: "white" }}
            borderBottom={mensagens ? "2px solid #05a306" : null} // Condicional correta para definir borderBottom
            color="gray.800"
            onClick={handleMensagens}
          >
            <Icon as={FaComment} mr={3} />
            Mensagens
          </Box>
        </HStack>
        {sobre ? <Sobre /> : null}
        {donor ? (
          pageData.donor.length > 0 ? (
            pageData.donor.map((donor, index) => (
              <Doadores
                key={index}
                image={donor.image}
                name={donor.name}
                amount={donor.amount}
                timeAgo={donor.timeAgo}
                isLast={index === pageData.donor.length - 1} // Passa a informação se é o último item
              />
            ))
          ) : (
            <p>Nenhum doador encontrado.</p> // Mensagem opcional para quando não houver donor
          )
        ) : null}
        {mensagens ? (
          pageData.messages.length > 0 ? (
            pageData.messages.map((message, index) => (
              <Mensagens
                key={index}
                image={message.image}
                name={message.name}
                message={message.message}
                timeAgo={message.timeAgo}
                isLast={index === pageData.messages.length - 1} // Passa a informação se é o último item
              />
            ))
          ) : (
            <p>Nenhuma mensagem encontrada.</p> // Mensagem opcional para quando não houver donor
          )
        ) : null}
        <CardDonation />
        <Checkout />{" "}
        <Share />{" "}
        <Divider
          orientation="horizontal"
          mt={5}
          w="90%"
          borderColor="gray.300"
          borderWidth="1px"
        />
        <Heading mt={10}>Ajude Também:</Heading>
        <Box pos="relative" w={"100%"} maxW={"100%"} mt={5}>
          <Swiper
            spaceBetween={8}
            slidesPerView={2}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            modules={[Pagination, A11y]}
          >
            {data
              .filter((page) => page.endpoint !== pageData.endpoint)
              .map((page, index) => (
                <SwiperSlide key={index}>
                  <Card
                    borderTop="8px"
                    borderColor="green.500"
                    width="100%" // Garante que o Card ocupe 100% da largura do SwiperSlide
                  >
                    <CardHeader p={0}>
                      <Image
                        src={`/assets/images/${page.images[0].name}`}
                        alt={`Image ${index + 1}`}
                        objectFit="cover"
                        width="100%" // Garante que a imagem ocupe 100% da largura do Card
                        height="auto" // Mantém a proporção da imagem
                        borderBottomRadius={8} // Opcional: Aplica um radius na parte inferior da imagem
                      />
                    </CardHeader>
                    <CardBody>
                      <Heading size="sm" mb={2} noOfLines={2}>
                        {page.subtitle}
                      </Heading>

                      <HStack justify="space-between" width="100%" mb={2}>
                        <Heading fontSize="10px">Arrecadado</Heading>
                        <Heading fontSize="8px">
                          {((page.AmountNow / page.AmountTarget) * 100)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")}{" "}
                          %
                        </Heading>
                      </HStack>
                      <ProgressBar
                        value={(page.AmountNow / page.AmountTarget) * 100}
                        colorScheme="green"
                        size="sm"
                      />
                      <HStack justify="space-between" width="100%" mt={2}>
                        <Heading color="green.500" fontSize="10px">
                          {formatCurrency(page.AmountNow)}
                        </Heading>
                        <Heading fontSize="10px">
                          de {formatCurrency(page.AmountTarget)}
                        </Heading>
                      </HStack>
                    </CardBody>
                    <CardFooter
                      mt={-8}
                      display="flex"
                      flexDirection={"column"}
                      textAlign={"center"}
                      justifyContent="center"
                    >
                      <Text mb={2} color={"red"} fontSize={12}>
                        Encerra em {page.DaysLeft} dias
                      </Text>
                      <Button
                        colorScheme="green"
                        variant="solid"
                        size="md"
                        as="a"
                        href={`/${page.endpoint}`}
                        w="100%"
                      >
                        Ver mais
                      </Button>
                    </CardFooter>
                  </Card>
                </SwiperSlide>
              ))}
          </Swiper>
        </Box>
      </Flex>
    </>
  );
}

export default Page;
