import {
  Box,
  Container,
  Stack,
  Text,
  Divider,
  HStack,
  VStack,
  Icon,
  Link,
  Image,
} from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import dataOthers from ".././api/others.json"; // Importe o arquivo JSON

function Footer() {
  return (
    <Box bg="gray.800" color="gray.200" py={10}>
      <Container
        maxW="container.lg"
        textAlign="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Stack
          direction={["column", "row"]}
          justify="space-between"
          spacing={8}
        >
          <VStack align="center">
            <Link to="/">
              <Image
                h={{ base: "70px", md: "70px", lg: "80px" }}
                src={`/assets/images/${dataOthers.logo2}`}
              />
            </Link>
            <Text fontWeight="bold" fontSize="lg">
              Políticas
            </Text>
            <Link href="/policy#privacy" _hover={{ color: "green.300" }}>
              Política de Privacidade
            </Link>
            <Link href="/policy#terms" _hover={{ color: "green.300" }}>
              Termos de Uso
            </Link>
            <Link href="/policy#cookies" _hover={{ color: "green.300" }}>
              Política de Cookies
            </Link>
          </VStack>

          <VStack align="center">
            <Text fontWeight="bold" fontSize="lg">
              Endereço
            </Text>
            <Text>
              Rua Claudinei Bitto, 123 <br />
              Loteamento Aldrovândia Gleba 2, Indaiatuba - SP <br />
              CEP: 13336-205
            </Text>
            <Text fontWeight="bold">CNPJ: 24.159.578/0001-53</Text>
          </VStack>

          <VStack align="center">
            <Text fontWeight="bold" fontSize="lg">
              Links Úteis
            </Text>
            <Link href="/transparency" _hover={{ color: "green.300" }}>
              Transparência
            </Link>
            <Link href="/create" _hover={{ color: "green.300" }}>
              Criar Doação
            </Link>
            <Link href="/contact" _hover={{ color: "green.300" }}>
              Contato
            </Link>
          </VStack>
        </Stack>

        <Divider my={8} borderColor="gray.600" />

        <HStack spacing={4} justifyContent="center">
          <Link href="https://www.facebook.com" isExternal>
            <Icon as={FaFacebook} boxSize={6} _hover={{ color: "green.300" }} />
          </Link>
          <Link href="https://www.twitter.com" isExternal>
            <Icon as={FaTwitter} boxSize={6} _hover={{ color: "green.300" }} />
          </Link>
          <Link href="https://www.instagram.com" isExternal>
            <Icon as={FaInstagram} boxSize={6} _hover={{ color: "green.300" }} />
          </Link>
        </HStack>

        <Text mt={4}>
          &copy; 2024 Sementes do Bem. Todos os direitos reservados.
        </Text>
      </Container>
    </Box>
  );
}

export default Footer;
