import { useParams } from "react-router-dom";
import data from "../api/paginas.json"; // Ajuste o caminho para o seu JSON
import {
  HStack,
  Heading,
  CardBody,
  CardHeader,
  CardFooter,
  Card,
  Image,
  Progress,
  Text,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
const Search = () => {
  const { params } = useParams();

  // Filtre os itens com base no ID

  const results = data.filter((item) => item.title.includes(params));

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(amount);
  };

  const ProgressBar = ({ value, colorScheme, size, label }) => {
    return (
      <Box>
        {label && <Text mb={2}>{label}</Text>}
        <Progress
          value={value}
          colorScheme={colorScheme}
          size={size}
          hasStripe
          isAnimated
        />
      </Box>
    );
  };

  return (
    <>
      <Flex flexDirection={"column"} textAlign={"center"} mt={5} p={3}>
        <Heading textAlign={"center"} mb={5} size={"lg"}>
          Resultados da Busca ({results.length})
        </Heading>
        {results.length > 0 ? (
          <Flex direction="column" width="100%">
            {results.map((result, index) => (
              <Card
                key={index}
                borderTop="8px"
                borderColor="green.500"
                width="100%" // Garante que o Card ocupe 100% da largura do Flex
                mb={10}
              >
                <CardHeader p={0}>
                  <Image
                    src={`/assets/images/${result.images[0].name}`}
                    alt={`Image ${index + 1}`}
                    objectFit="cover"
                    width="100%" // Garante que a imagem ocupe 100% da largura do Card
                    height="auto" // Mantém a proporção da imagem
                    borderBottomRadius={8} // Opcional: Aplica um radius na parte inferior da imagem
                  />
                </CardHeader>
                <CardBody>
                  <Heading size="lg" mb={5} noOfLines={2}>
                    {result.subtitle}
                  </Heading>

                  <HStack justify="space-between" width="100%" mb={2}>
                    <Heading fontSize="16px">Arrecadado</Heading>
                    <Heading fontSize="14px">
                      {((result.AmountNow / result.AmountTarget) * 100)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",")}{" "}
                      %
                    </Heading>
                  </HStack>
                  <ProgressBar
                    value={(result.AmountNow / result.AmountTarget) * 100}
                    colorScheme="green"
                    size="sm"
                  />
                  <HStack justify="space-between" width="100%" mt={2}>
                    <Heading color="green.500" fontSize="16px">
                      {formatCurrency(result.AmountNow.toFixed(2))}
                    </Heading>
                    <Heading fontSize="16px">
                      de {formatCurrency(result.AmountTarget.toFixed(2))}
                    </Heading>
                  </HStack>
                </CardBody>
                <CardFooter
                  mt={-8}
                  display="flex"
                  flexDirection={"column"}
                  textAlign={"center"}
                  justifyContent="center"
                >
                  <Text mb={2} color={"red"} fontSize={16}>
                    Encerra em {result.DaysLeft} dias
                  </Text>
                  <Button
                    colorScheme="green"
                    variant="solid"
                    size="md"
                    as="a"
                    href={`/${result.endpoint}`}
                    w="100%"
                  >
                    Ver mais
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </Flex>
        ) : (
          <Heading mt={"50%"} fontSize={16} h={"50vh"}>
            Nenhum resultado correspondente
          </Heading>
        )}
      </Flex>
    </>
  );
};

export default Search;
