import {
  Flex,
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";

function Policy() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = () => {
    if (!email || !phone) {
      toast({
        title: "Erro",
        description: "Por favor, preencha todos os campos.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    toast({
      title: "Formulário enviado com sucesso!",
      description: "Entraremos em contato em breve.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "top",
    });

    // Limpar os campos após o envio (opcional)
    setEmail("");
    setPhone("");
  };
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection={"column"}
      textAlign={"center"}
      p={4}
    >
      <Box mb={10}>
        <Heading as="h2" size="lg" mb={4}>
          Transparência
        </Heading>
        <Text mb={5}>
          Todas as doações feitas por aqui são recebidas através de um receptor
          de pagamentos online e enviadas imediatamente para o beneficiário
          (quem precisa de ajuda) mediante a cobrança de taxas da plataforma de
          recebimentos.
        </Text>
        <Text mb={5}>
          Note que esta taxa não é cobrada por nós, mas sim pela empresa que
          intermedia o seu pagamento.
        </Text>

        <Text mb={5}>
          Caso você tenha doado para alguém e queira receber atualizações sobre
          o quadro da mesma, por favor, preencha o formulário abaixo.
        </Text>
        <Text>Vamos te manter atualizado sempre que possível!</Text>
      </Box>
      <Heading as="h2" size="lg" mb={4}>
        Quero me manter Atualizado!
      </Heading>
      <Box width="100%" maxW="md" p={6} borderRadius="md" boxShadow="lg">
        <FormControl id="email" mb={4} isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        <FormControl id="phone" mb={4} isRequired>
          <FormLabel>Telefone</FormLabel>
          <Input
            type="tel"
            placeholder="Seu telefone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </FormControl>

        <Button colorScheme="green" width="full" onClick={handleSubmit}>
          Enviar
        </Button>
      </Box>
    </Flex>
  );
}

export default Policy;
